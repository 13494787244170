import ApiService from "@/core/services/api.service";
import {assign} from 'lodash';
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: 'aes', isCompression: true });
import { responseError } from '@/core/mixin/shared'

// GETTERS
export const AUTH = 'AUTH/AUTH';
export const USER = 'AUTH/AUTH';

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_SUCCESS_MESSAGE = "setSuccessMessage";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGIN_SMS = "loginSMS";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";


const state = {
    errors: null,
    successMessage: null,
    user: {},
    token: {},
    isAuthenticated: false,
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    [AUTH]: (state) => {
        return state.user;
    },
    token(state) {
        return state.token;
    },
    isAuthenticated(state) {
        try {
            return ls.get('token');
        }
        catch (e) {
            ls.remove('token');
            ls.remove('refresh_token');
            return false;
        }
    }
};

const mutations = {
    [SET_ERROR](state, payload) {
        state.errors = responseError(payload);
    },
    [SET_SUCCESS_MESSAGE](state, message) {
        state.successMessage = message;
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        state.user = user;
        state.errors = null;
        state.token = {
            access_token: user.access_token,
            refresh_token: user.refresh_token,
            expires_in: user.expires_in,
        };
        ls.set('token', state.user.access_token);
        ls.set('refresh_token', state.user.refresh_token);
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = null;
        state.errors = null;
        ls.remove('token');
        ls.remove('refresh_token');
        ls.remove('user');
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise(resolve => {
            credentials = assign({}, credentials, {'grant_type': 'password', 'login_source': 1})
            ApiService.post("api/token", credentials)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch((error) => {
                    context.commit(SET_ERROR, error);
                });
        });
    },

    [LOGIN_SMS](context, credentials) {
        return new Promise(resolve => {
            credentials = assign({}, credentials)
            ApiService.post("api/token", credentials)
                .then(({ data }) => {
                    if (data.hasOwnProperty('message')) {
                        context.commit(SET_SUCCESS_MESSAGE, data.message);
                    } else {
                        context.commit(SET_SUCCESS_MESSAGE, data);
                    }
                    resolve(data);
                })
                .catch((error) => {
                    context.commit(SET_ERROR, error);
                });
        });
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [VERIFY_AUTH](context) {
        if (!ls.get('token')) {
            context.commit(PURGE_AUTH);
        }
    },
    [UPDATE_USER](context, payload) {
        const {email, username, password, image, bio} = payload;
        const user = {email, username, bio, image};
        if (password) {
            user.password = password;
        }

        return ApiService.put("user", user).then(({data}) => {
            context.commit(SET_AUTH, data);
            return data;
        });
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
